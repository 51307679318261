<template>
  <div class="main">
    <el-row style="width: 500px;" class='box'>
      <el-col :span='12' class="box-left">
        <img src="../assets/userImg.png" alt="" class="img">
      </el-col>
      <el-col :span='12' class="box-right">
        <div class="box-right-box">
          <div class="title">健康管理文章系统</div>
          <el-form ref="form" :model="form" :rules="rules" class="form" label-width="80px" size="medium">
            <el-form-item prop="type" label="身份">
              <el-radio-group v-model="form.type">
                <el-radio :label="1">医生</el-radio>
                <el-radio :label="2">管理员</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item prop="account" label="账号">
              <el-input placeholder="请填写账号" v-model="form.account">
              </el-input>
            </el-form-item>
            <el-form-item prop="password" label="密码">
              <el-input type="password" placeholder="请填写密码" v-model="form.password">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSubmit" class="login-form-button" block>
                登录
              </el-button>
            </el-form-item>
          </el-form>
        </div>

      </el-col>
    </el-row>
  </div>
</template>

<script>
  var storage = window.localStorage;
  export default {
    data() {
      return {
        form: {},
        btnStatus: false,
        isLoginError: false,
        rules: {
          type: [{
            required: true,
            message: '请选择身份',
            trigger: 'change'
          }],
          account: [{
            required: true,
            message: '请填写账号',
            trigger: 'blur'
          }],
          password: [{
            required: true,
            message: '请填写密码',
            trigger: 'blur'
          }],
        },
      }
    },

    methods: {
      handleSubmit() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.$http.post('/index/login', {
							type: this.form.type,
							account: this.form.account,
							password: this.form.password
						}).then(res => {
							if (res.data.code == 1) {
								storage.loginStatus = '1'
								storage.aid = res.data.data.aid
								storage.type = res.data.data.type
								storage.name = res.data.data.name
								if(res.data.data.minipro){
									storage.minipro = res.data.data.minipro
								}
								this.$message.success('登录成功')
								setTimeout(() => {
									this.$router.push('/')
								}, 300)
							} else {
								this.$message.error(res.data.msg);
							}
						})
          } else {
            this.$message.error('登录失败');
          }
        });
      },
    }
  }
</script>

<style scoped lang="less">
  .main {
    background: #e8e8e8;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    /* background-image: url('../assets/img/loginbg.png'); */
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: center 110px; */
  }

  .box {
    border-radius: 10px;
    box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
    background: -webkit-linear-gradient(to bottom right, #ffb199, #ff0844) !important;
    background: linear-gradient(to bottom right, #ffb199, #ff0844) !important;
    width: 1000px !important;
    height: 400px;
  }

  .img {
    width: 340px;
    height: 340px;
  }

  .box-left {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: auto 0;
  }

  .box-right {
    height: 100%;
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;

    .box-right-box {
      height: 100%;
      width: 100%;
      padding: 40px;
      background: white;
      border-radius: 10px;

      .title {
        text-align: center;
        font-size: 30px;
        font-weight: 700;
        margin-top: 30px;
        margin-bottom: 60px;
      }

      .login-form-button {
        margin-top: 30px;
        background: #ff0844;
        border: #ff0844;
      }
    }
  }
</style>